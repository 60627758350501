<template>
  <div class="js-common-wrap">
    <div class="js-common-head">
      <label>人像数据库</label>
      <div>
        <el-button type="primary" size="small" @click="add(0,'')">新增</el-button>
      </div>
    </div>
    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据" @row-click="handleRowClick">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="name" label="库名"></el-table-column>
        <el-table-column label="是否激活">
          <template slot-scope="scope">
            <span v-if="scope.row.status">已激活</span>
            <span v-else>未激活</span>
          </template>
        </el-table-column>
        <el-table-column prop="gmtCreated" label="创建时间"></el-table-column>
        <el-table-column prop="gmtUpdated" label="上次修改时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline" @click.stop="add(1,scope.row)"></i>
            <i class="el-icon-delete" @click.stop="deleteItem(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination v-model="pageOptions" @curPageChange="handleCurPageChange"></common-pagination>
    <face-reco-data-add v-model="addDialogVisible" :currentItem="currentItem" :isModify="isModify" @handleAdd="handleAdd"></face-reco-data-add>
    <common-delete v-model="deleteDialogVisible" @handleDelete="handleDelete"></common-delete>
  </div>
</template>
<script>
import CommonPagination from '@/components/CommonPagination'
import FaceRecoDataAdd from '@/components/FaceRecoDataAdd'
import CommonDelete from '@/components/CommonDelete'
export default {
  name: '',
  components: {
    CommonPagination,
    FaceRecoDataAdd,
    CommonDelete
  },
  data() {
    return {
      tableData: [],
      addDialogVisible: false,
      deleteDialogVisible: false,
      pageOptions:{
        page: 1,
        perPage: 20,
        total: 0
      },
      isModify: '',
      currentItem: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(){
      this.$axios({
        method: 'get',
        url: 'api/csp/afr/v1/afrLib/page'
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.tableData = data.data.rows
          this.pageOptions.total = data.data.total
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 分页
    handleCurPageChange(){
      this.getList()
    },
    add(flag,item){
      this.isModify = flag
      this.addDialogVisible = true
      if(item){
        this.currentItem = this.$Utils.deepCopy(item)
      }else{
        this.currentItem = {
          name: '',
          status: 1
        }
      }
    },
    handleAdd(params){
      let method = ''
      let url = ''
      if(this.isModify){
        method = 'put'
        url = `api/csp/afr/v1/afrLib/${this.currentItem.id}`
      }else{
        method = 'post'
        url = 'api/csp/afr/v1/afrLib/'
      }
      this.$axios({
        method: method,
        url: url,
        data: {
          data: {
            name: params.name,
            status: params.status
          }
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.addDialogVisible = false
          this.$message({
            type: 'success',
            message: '新增成功！',
            duration: 1000
          })
          this.pageOptions.page = 1
          this.getList()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    deleteItem(item){
      this.currentItem = item
      this.deleteDialogVisible = true
    },
    handleDelete(){
      this.$axios({
        method: 'delete',
        url: `api/csp/afr/v1/afrLib/${this.currentItem.id}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.deleteDialogVisible = false
          this.$message({
            type: 'success',
            message: '删除成功！',
            duration: 1000
          })
          this.pageOptions.page = 1
          this.getList()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    handleRowClick(row){
      this.$router.push({
        path: `/aiRecognition/faceData/details`,
        query:{
          id:row.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
