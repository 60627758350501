var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form",
      attrs: {
        title: _vm.isModify ? "修改库信息" : "添加库信息",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "560px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("ul", [
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("库名称"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入库名称", size: "small" },
              model: {
                value: _vm.params.name,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "name", $$v)
                },
                expression: "params.name",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label" }, [_vm._v("是否激活")]),
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.params.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "status", $$v)
                  },
                  expression: "params.status",
                },
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }