<template>
  <el-dialog class="js-dialog-form" :title="isModify?'修改库信息':'添加库信息'" :visible.sync="dialogVisible" :close-on-click-modal="false" width="560px">
    <ul>
      <!-- <li class="label common-tip"><i>*</i>库名称</li> -->
      <li>
        <div class="label common-tip"><i>*</i>库名称</div>
        <el-input v-model="params.name" placeholder="请输入库名称" size="small"></el-input>
      </li>
      <li>
        <div class="label">是否激活</div>
        <el-radio-group v-model="params.status" size="small">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: ['value','isModify','currentItem'],
  data() {
    return {}
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    },
    params(){
      return this.currentItem
    }
  },
  mounted() {
  },
  methods: {
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      this.$emit('handleAdd',this.params)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>